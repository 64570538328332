import * as React from "react"
import { addPropertyControls, ControlType, RenderTarget } from "framer"

/**
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight auto
 */
export default function Code_Snippet(props) {
    const { code, id } = props

    // We use the Framer node id for a stable id
    const elementId = `code_${id}`

    // We use a script loader in a useEffect
    React.useEffect(() => {
        const container = document.getElementById(elementId)

        container.innerHTML = code

        return () => {
            container.innerHTML = ""
            container.remove()
        }
    }, [code, id])

    return <div initial={false} style={style} id={elementId} />
}

const style = {
    width: "100%",
    height: "100%",
    minHeight: "50",
    fontSize: "16px",
}

// Add a form id field in the properties panel
addPropertyControls(Code_Snippet, {
    code: {
        title: "Code",
        type: ControlType.String,
        displayTextArea: true,
        //defaultValue: "",
    },
})
